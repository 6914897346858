/**
 * @file Manages the calendar / day-picker view.
 */

import { Key } from '../lib/dom';
import {
  now, datesEq, shiftMonth, shiftDay
} from '../lib/date-manip';

function mapDays(currentDate, dayOffset, fn) {
  let result = '';
  const iter = new Date(currentDate);
  iter.setDate(1);
  iter.setDate(1 - iter.getDay() + dayOffset);

  // If we are showing monday as the 1st of the week,
  // and the monday is the 2nd of the month, the sunday won't
  // show, so we need to shift backwards
  if (dayOffset && iter.getDate() === dayOffset + 1) {
    iter.setDate(dayOffset - 6);
  }

  // We are going to have 6 weeks always displayed to keep a consistent
  // calendar size
  for (let day = 0; day < (6 * 7); day += 1) {
    result += fn(iter);
    iter.setDate(iter.getDate() + 1);
  }

  return result;
}

/**
 * view renders the calendar (day picker) as an HTML string.
 *
 * @param {DatePickerContext} context the date picker being rendered
 * @returns {string}
 */
function render(dp) {
  const opts = dp.opts;
  const lang = opts.lang;
  const state = dp.state;
  const dayNames = lang.days;
  const dayOffset = opts.dayOffset || 0;
  const selectedDate = state.selectedDate;
  const hilightedDate = state.hilightedDate;
  const hilightedMonth = hilightedDate.getMonth();
  const today = now().getTime();

  return (
    `${'<div class="dp-cal">'
      + '<header class="dp-cal-header">'
        + '<button tabindex="0" type="button" class="dp-prev">Prev</button>'
        + '<button tabindex="0" type="button" class="dp-cal-month">'}${
      lang.months[hilightedMonth]
    }</button>`
        + `<button tabindex="0" type="button" class="dp-cal-year">${
          hilightedDate.getFullYear()
        }</button>`
        + '<button tabindex="0" type="button" class="dp-next">Next</button>'
      + '</header>'
      + `<div class="dp-days">${
        dayNames.map((name, i) => (
          `<span class="dp-col-header">${dayNames[(i + dayOffset) % dayNames.length]}</span>`
        )).join('')
      }${mapDays(hilightedDate, dayOffset, (date) => {
        const isNotInMonth = date.getMonth() !== hilightedMonth;
        const isDisabled = !opts.inRange(date);
        const isToday = date.getTime() === today;
        let className = 'dp-day';
        className += (isNotInMonth ? ' dp-edge-day' : '');
        className += (datesEq(date, hilightedDate) ? ' dp-current' : '');
        className += (datesEq(date, selectedDate) ? ' dp-selected' : '');
        className += (isDisabled ? ' dp-day-disabled' : '');
        className += (isToday ? ' dp-day-today' : '');
        className += ` ${opts.dateClass(date, dp)}`;

        return (
          `<button tabindex="0" type="button" class="${className}" data-date="${date.getTime()}">${
            date.getDate()
          }</button>`
        );
      })
      }</div>`
      + '<footer class="dp-cal-footer">'
      + `<button tabindex="0" type="button" class="dp-today">${lang.today}</button>`
      + `<button tabindex="0" type="button" class="dp-clear">${lang.clear}</button>`
        + `<button tabindex="0" type="button" class="dp-close">${lang.close}</button>`
        + '</footer>'
    + '</div>'
  );
}

/**
 * keyDown handles the key down event for the day-picker
 *
 * @param {Event} e
 * @param {DatePickerContext} dp
 */
function keyDown(e, dp) {
  const pressedKey = e.keyCode;
  const shift = (key) => {
    switch (key) {
      case Key.left:
        return -1;
      case Key.right:
        return 1;
      case Key.up:
        return -7;
      case Key.down:
        return 7;
      default:
        return 0;
    }
  };
  const shiftBy = shift(pressedKey);

  if (pressedKey === Key.esc) {
    dp.close();
  } else if (shiftBy) {
    e.preventDefault();
    dp.setState({
      hilightedDate: shiftDay(dp.state.hilightedDate, shiftBy)
    });
  }
}

function selectToday(e, dp) {
  dp.setState({
    selectedDate: now(),
  });
}

function clear(e, dp) {
  dp.setState({
    selectedDate: null,
  });
}

function close(e, dp) {
  dp.close();
}

function showMonthPicker(e, dp) {
  dp.setState({
    view: 'month'
  });
}

function showYearPicker(e, dp) {
  dp.setState({
    view: 'year'
  });
}

function gotoNextMonth(e, dp) {
  const hilightedDate = dp.state.hilightedDate;
  dp.setState({
    hilightedDate: shiftMonth(hilightedDate, 1)
  });
}

function gotoPrevMonth(e, dp) {
  const hilightedDate = dp.state.hilightedDate;
  dp.setState({
    hilightedDate: shiftMonth(hilightedDate, -1)
  });
}

function selectDay(e, dp) {
  dp.setState({
    selectedDate: new Date(parseInt(e.target.getAttribute('data-date'))),
  });
}

export default {
  onKeyDown: keyDown,
  onClick: {
    'dp-day': selectDay,
    'dp-next': gotoNextMonth,
    'dp-prev': gotoPrevMonth,
    'dp-today': selectToday,
    'dp-clear': clear,
    'dp-close': close,
    'dp-cal-month': showMonthPicker,
    'dp-cal-year': showYearPicker,
  },
  render
};
