/**
 * @file Manages the year-picker view.
 */

import {Key} from '../lib/dom';
import {setYear, shiftYear, constrainDate} from '../lib/date-manip';

function mapYears(dp, fn) {
  let result = '';
  const max = dp.opts.max.getFullYear();

  for (let i = max; i >= dp.opts.min.getFullYear(); i -= 1) {
    result += fn(i);
  }

  return result;
}

/**
 * view renders the year picker as an HTML string.
 *
 * @param {DatePickerContext} dp the date picker context
 * @returns {string}
 */
function render(dp) {
  const state = dp.state;
  const currentYear = state.hilightedDate.getFullYear();
  const selectedYear = state.selectedDate.getFullYear();

  return (
    `<div class="dp-years">${
      mapYears(dp, (year) => {
        let className = 'dp-year';
        className += (year === currentYear ? ' dp-current' : '');
        className += (year === selectedYear ? ' dp-selected' : '');

        return (
          `<button tabindex="0" type="button" class="${className}" data-year="${year}">${
            year
          }</button>`
        );
      })
    }</div>`
  );
}

function onChooseYear(e, dp) {
  dp.setState({
    hilightedDate: setYear(dp.state.hilightedDate, parseInt(e.target.getAttribute('data-year'))),
    view: 'day',
  });
}

function keyDown(e, dp) {
  const opts = dp.opts;
  const pressedKey = e.keyCode;
  const shift = (key) => {
    switch (key) {
      case Key.left:
      case Key.up:
        return 1;
      case Key.right:
      case Key.down:
        return -1;
      default:
        return 0;
    }
  };
  const shiftBy = shift(pressedKey);

  if (pressedKey === Key.esc) {
    dp.setState({
      view: 'day',
    });
  } else if (shiftBy) {
    e.preventDefault();
    const shiftedYear = shiftYear(dp.state.hilightedDate, shiftBy);

    dp.setState({
      hilightedDate: constrainDate(shiftedYear, opts.min, opts.max),
    });
  }
}

export default {
  render,
  onKeyDown: keyDown,
  onClick: {
    'dp-year': onChooseYear
  },
};
