/**
 * @file Manages the month-picker view.
 */

import { Key } from '../lib/dom';
import { shiftMonth, setMonth } from '../lib/date-manip';

function onChooseMonth(e, dp) {
  dp.setState({
    hilightedDate: setMonth(dp.state.hilightedDate, parseInt(e.target.getAttribute('data-month'))),
    view: 'day',
  });
}

/**
 * render renders the month picker as an HTML string
 *
 * @param {DatePickerContext} dp the date picker context
 * @returns {string}
 */
function render(dp) {
  const opts = dp.opts;
  const lang = opts.lang;
  const months = lang.months;
  const currentDate = dp.state.hilightedDate;
  const currentMonth = currentDate.getMonth();

  return (
    `<div class="dp-months">${
      months.map((month, i) => {
        let className = 'dp-month';
        className += (currentMonth === i ? ' dp-current' : '');

        return (
          `<button tabindex="0" type="button" class="${className}" data-month="${i}">${
            month
          }</button>`
        );
      }).join('')
    }</div>`
  );
}

/**
 * keyDown handles keydown events that occur in the month picker
 *
 * @param {Event} e
* @param {DatePickerContext} dp
 */
function keyDown(e, dp) {
  const pressedKey = e.keyCode;
  const shift = (key) => {
    switch (key) {
      case Key.left:
        return -1;
      case Key.right:
        return 1;
      case Key.up:
        return -3;
      case Key.down:
        return 3;
      default:
        return 0;
    }
  };
  const shiftBy = shift(pressedKey);

  if (pressedKey === Key.esc) {
    dp.setState({
      view: 'day',
    });
  } else if (shiftBy) {
    e.preventDefault();
    dp.setState({
      hilightedDate: shiftMonth(dp.state.hilightedDate, shiftBy, true)
    });
  }
}

export default {
  onKeyDown: keyDown,
  onClick: {
    'dp-month': onChooseMonth
  },
  render
};
