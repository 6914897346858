/* eslint-disable no-restricted-syntax */
import intlTelInput from 'intl-tel-input';

import TinyDatePicker from '../selectAttributes/datePicker';
import { translate, charsetEscape } from '../utils/translate';
import { addPixelToStyle } from '../utils/addPixelToStyle';
import ToastAlert from '../toastAlert';
import { CLEVERPUSH_API_ENDPOINTS, getApiEndpoint } from '../utils/getApiEndpoint';

import './intlTelInput.min.css';
import './email.scss';

import CleverPushApi from '../api';

const FieldTypeToName = {
  email: 'emailAddress',
  tel: 'phoneNumber',
};

const autoField = {
  email: 'email',
  phoneNumber: 'phoneNumber',
};

const dateRegex = /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/g;

const FILED_OPTIONS_VISIBILITY_OPTION = {
  All: 'All',
  showAfterSubcrtipion: 'showAfterSubscription',
  showWithOutSubscription: 'showWithOutSubscription',
};

function inputType({ type, attribute }) {
  return FieldTypeToName[type] || `customAttributes[${attribute}]`;
}

const DEFAULT_TIME_TO_SHOW_TOAST = 10000;
const AUTOMATIC_SCROLL_BEHAVIOR = { behavior: 'smooth', block: 'start', inline: 'nearest' };
const API_ENDPOINT = 'https://api.mycleverpush.com';
const openRedirectUrl = (url, newPage) => window.open(url, newPage ? '_blank' : '_self');

class CleverPushEmail {
  constructor() {
    if (window.cleverpushEmailConfig) {
      this.config = { ...window.cleverpushEmailConfig };
      if (this.config.topics?.length) {
        this.overrideTopics = [...this.config.topics];
      }
    } else if (window.cleverpushConfig) {
      this.config = { ...window.cleverpushConfig };
    } else if (window.CleverPush) {
      this.config = { ...window.CleverPush.config };
    }

    this.channelId = this.config.channelId;
    this.subscriptionId = new URLSearchParams(window.location.search)?.get('subscriptionId');

    if (!this.channelId) {
      console.warn('CleverPush: channelId not found anywhere');
      return;
    }

    this.api = new CleverPushApi(this.channelId);

    this.config.apiEndpoint = getApiEndpoint();
    if (this.config.apiEndpoint === CLEVERPUSH_API_ENDPOINTS.PRODUCTION) {
      // override API endpoint to mycleverpush.com domain so it will not get blocked by adblockers
      this.config.apiEndpoint = API_ENDPOINT;
    }

    if (this.config.apiEndpoint) {
      this.api.endpoint = this.config.apiEndpoint;
    }

    if (this.config.env === 'PREVIEW') {
      const domForm = document.querySelector('.cleverpush-email-form');
      this.initForm(domForm, this.config.widgets?.length && this.config.widgets[0].emailOptInFormSetting ? this.config.widgets[0].emailOptInFormSetting : this.config.form);
    } else if (!window.cleverPushEmailPreventInit) {
      this.loadConfig(this.channelId).then(() => {
        this.initForms();
      });
    }
  }

  loadConfig() {
    return new Promise((resolve) => {
      if (this.loadingConfig) {
        this.loadingConfigCallbacks.push(resolve);
        return;
      }

      this.loadingConfig = true;
      this.loadingConfigCallbacks = [resolve];

      this.api.getChannelConfig().then((config) => {
        this.config = config;
        this.loadingConfig = false;
        this.loadingConfigCallbacks.forEach((callback) => callback(config));
      });
    });
  }

  initForms() {
    (this.config.emailOptInForms || []).forEach((form) => {
      const domForms = document.querySelectorAll(`.cleverpush-email-form[data-id="${form._id}"]`);
      for (let i = 0; i < domForms.length; i++) {
        this.initForm(domForms[i], form);
      }
    });
  }

  renderOptinForm(field, id, required, config) {
    if ((field.type === 'checkbox' || field.type === 'radio') && field.possibleValues?.length) {
      return this.renderOptinFormCheckBoxAndRadio(field, required, config);
    }

    if ((field.type === 'select' || field.type === 'dropdown') && field.possibleValues?.length) {
      return this.renderOptinFormSelectField(field, required, config);
    }

    return this.renderOptinFormTopics(field, id, required, config);
  }

  renderOptinFormSelectField(field, required, config) {
    return `
    <div class="cleverpush-email-field">
      ${(field.label) ? `<label for="${`input-${field.attribute || field.type}`}">${field.label}${required ? `<span style="margin-left: 5px; color: ${(config.labelStyle || {}).mandatoryAsteriskColor || 'red'};">*</span>` : ''}</label>` : ''}
      <div>
        <select class="cleverpush-email-select" name="${inputType(field)}">
          ${field.possibleValues?.map((value) => `<option value="${value}">${value}</option>`).join('')}
        </select>
      </div>
    </div>
    `;
  }

  renderOptinFormCheckBoxAndRadio(field, required, config) {
    return `
      <div class="cleverpush-email-field">
        ${(field.label) ? `<label for="${`input-${field.attribute || field.type}`}">${field.label}${required ? `<span style="margin-left: 5px; color: ${(config.labelStyle || {}).mandatoryAsteriskColor || 'red'};">*</span>` : ''}</label>` : ''}
        <div>
          ${field.possibleValues?.map((value, i) => {
            if (field.type === 'checkbox') {
              return `<div class="cleverpush-email-checkbox"><input id="${`input-${field.attribute || field.type}-${i}`}" type="${field.type}" name="${inputType(field)}" value="${value.replace(/<[^>]*>/g, '').replace(/"/g, "'")}" ${field.required ? 'required' : ''} /><label for="${`input-${field.attribute || field.type}-${i}`}" ${field.inline ? 'style="display: inline-block; margin-right: 15px;"' : ''}>${value}</label></div>`;
            }
            return `<label for="${`input-${field.attribute || field.type}-${i}`}" ${field.inline ? 'style="display: inline-block; margin-right: 15px;"' : ''}><input id="${`input-${field.attribute || field.type}-${i}`}" type="${field.type}" name="${inputType(field)}" value="${value.replace(/"/g, "'")}" ${field.required ? 'required' : ''} />${value}</label>`;
          }).join('')}
        </div>
      </div>
    `;
  }

  renderOptinFormTopics(field, id, required, config) {
    const inputField = `${field.type !== 'topics' ? `<input type="${field.type}" name="${inputType(field)}" id="${`input-${field.attribute || field.type}`}" data-required-error="${field.requiredErrorMessage || ''}" data-required-error-mismatch="${field.requiredErrorMismatchMessage || ''}" placeholder="${(field.attribute === 'birthdate' ? 'TT.MM.JJJJ' : field.placeholder) || ''}" ${required ? 'required' : ''} ${field.value ? `value="${field.value}"` : ''} />` : ''}`;
    const isTopicAvailable = (field, topic) => !field.availableTopics || field.availableTopics?.length === 0 || field.availableTopics?.includes(topic._id);
    const isDefaultTopic = (field, topic) => field.defaultTopics?.includes(topic._id);

    return (
      `<div class="cleverpush-email-field" id="cleverpush-email-field-${id}">
        ${(field.label && (field.type === 'checkbox' || field.type === 'radio')) ? `<label for="${`input-${field.attribute || field.type}`}">` : ''}

        ${(field.label && field.type !== 'checkbox' && field.type !== 'radio') ? `<label for="${`input-${field.attribute || field.type}`}">${field.label}${required ? `<span style="margin-left: 5px; color: ${(config.labelStyle || {}).mandatoryAsteriskColor || 'red'};">*</span>` : ''}</label>` : ''}
        ${field.type === 'email' && config.enableInlineButtonPosition
        ? `<div style="display: flex; align-items: center; gap: 10px; width: 100%;">
            ${inputField}
            <button type="submit" class="cleverpush-email-button" style="margin-bottom: 15px;">
            ${config.buttonIcon ? `<image src="${config.buttonIcon}" class="cleverpush-email-button-icon"></image>` : ''}
            ${this.subscriptionId ? translate('panel.save') : (config.buttonContent || config.submitText || 'Absenden')}
            </button>
          </div>`
        : inputField}

        ${field.type === 'topics' ? `
        <div class="cleverpush-email-topics">
          ${this.config.channelTopics
        .filter((topic) => isTopicAvailable(field, topic) || isDefaultTopic(field, topic))
        .sort((a, b) => a.sort - b.sort)
        .map((topic) => {
          const isAvailable = isTopicAvailable(field, topic);
          const isDefault = isDefaultTopic(field, topic);
          if (isDefault && !isAvailable) {
            return `<input type="hidden" name="topics[]" value="${topic._id}" checked>`;
          }
          const topicName = charsetEscape(topic.name);
          const topicDescription = charsetEscape(topic.description);
          const topicSubtitle = charsetEscape(topic.subtitle);
          const topicImage = charsetEscape(topic.imageUrl);
          const childTopics = this.config.channelTopics.filter((childTopic) => childTopic.parentTopic === topic._id);
          let topicSelection = `
          <label class="cleverpush-email-topic ${childTopics.length ? 'topic-has-children' : ''}">
            <div class="cleverpush-email-topic-select-checkbox cleverpush-email-checkbox">
              <input
                data-required-error="${field.requiredErrorMessage || ''}" 
                type="${this.config.confirmAlertRadioButtonTopics ? 'radio' : 'checkbox'}" 
                name="topics[]" ${isAvailable && isDefault ? 'checked' : ''} 
                value="${topic._id}"
                id="${`cleverpush-email-topic-checkbox-${topic._id}`}"
                ${required ? 'required' : ''}
              >
              <label
                for="${`cleverpush-email-topic-checkbox-${topic._id}`}"
              ></label>
            </div>
            <div class="cleverpush-topic-details">
              ${topicName ? `<div class="cleverpush-email-topic-name">${topicName}</div>` : ''} 
              ${topicSubtitle ? `<div class="cleverpush-email-topic-subtitle">${topicSubtitle}</div>` : ''}
              ${topicDescription ? `<div class="cleverpush-email-topic-description">${topicDescription}</div>` : ''}
            </div>
            ${topicImage ? `<div class="cleverpush-email-topic-image">
                <img src="${topicImage}" alt="${topicName}"  />
            </div> ` : ''}
          </label>`;
          if (childTopics.length) {
            childTopics.forEach((childTopic) => {
              const childTopicName = charsetEscape(childTopic.name);

              const childInputType = this.config.confirmAlertRadioButtonTopics || topic.childTopicsRadioButtons ? 'radio' : 'checkbox';

              topicSelection += `<div class="topic-child" data-parent-topic="${topic._id}" style="margin-left: 20px; font-size: 90%; ${false && topic.defaultUnchecked ? 'display: none;' : ''}"><label><input type="${childInputType}" name="topics[]" value="${childTopic._id}"> ${childTopicName}</label></div>`;
            });
          }

          return topicSelection;
        }).join('')
      }
</div>` : ''}

        ${(field.label && (field.type === 'checkbox' || field.type === 'radio')) ? `${field.label}</label>` : ''}
        </div>`
    );
  }

  initForm(formDiv, formConfig, sharedWidgetConfig = {}) {
    if (!formDiv || formDiv.dataset.initialized) {
      return;
    }

    formDiv.dataset.initialized = true;
    let config = formConfig;
    if (sharedWidgetConfig && sharedWidgetConfig.overrideEmailOptInFormStyle) {
      config = { ...config, ...(sharedWidgetConfig.emailOptInFormSetting || {}) };
    }

    if (config.iconStyleActive || (this.config.env)) {
      config = { ...config, iconStyle: config.iconStyle, widgetIconUrl: config.iconUrl || sharedWidgetConfig.iconUrl };
    }

    const randomId = Math.round(Math.random() * 1000000);
    const classNameId = `cleverpush-form-${config._id}-${randomId}`;
    formDiv.className += ` ${classNameId}`;
    const prefix = `.${classNameId}`;
    let id = 0;
    let editOptInId = 0;

    const style = document.createElement('style');
    const { topicElementStyle } = config;
    style.innerHTML = `
    ${prefix} fieldset { margin: 0 !important; padding: 0 !important; border: none !important; }
    ${prefix} .cleverpush-email-form-overlay { position: relative }
    ${prefix} .cleverpush-email-overlay-answer { width: 100%; position: absolute; top: 0; right: 0; bottom: 0; left: 0; text-align: center; display: flex; align-items: center; justify-content: center; padding: ${(config.style || {}).padding || 20}px; line-height: 1.5; flex-direction: column; }
    ${prefix} .cleverpush-email-form { ${addPixelToStyle(config.style || {})} }
    ${prefix} .cleverpush-email-form-icon { ${addPixelToStyle(config.iconStyle || {})} }
    ${prefix} .cleverpush-email-headline { ${addPixelToStyle(config.headlineStyle || {})} }
    ${prefix} .cleverpush-email-subtitle { ${addPixelToStyle(config.subtitleStyle || {})} }
    ${prefix} .cleverpush-email-description { ${addPixelToStyle(config.descriptionStyle || {})} }
    ${prefix} .cleverpush-email-field { ${addPixelToStyle(config.fieldStyle || {})} }
    ${prefix} .cleverpush-email-field label:not([for="input-checkbox"])[for^="input"][for] { ${addPixelToStyle(config.labelStyle || {})} }
    ${prefix} .cleverpush-email-field input, .cleverpush-email-field textarea { ${addPixelToStyle(config.inputStyle || {})} }
    ${prefix} .cleverpush-email-button { ${addPixelToStyle(config.buttonStyle || {})} }
    ${prefix} .cleverpush-email-button-icon { ${addPixelToStyle(config.buttonIconStyle || {})} }
    ${prefix} #input-checkbox {  -webkit-appearance: checkbox !important; appearance: checkbox !important;}
    ${config.widgetIconUrl
    ? ` @media (max-width: 600px) {
        ${prefix} .cleverpush-email-form {
          flex-wrap: wrap;
          justify-content: center; 
          }
  
          ${prefix} .cleverpush-email-form-icon {
            margin-right: 0 !important;
          }
      }` : ''
}
    
    ${config.selectStyleActive ? `${prefix} .cleverpush-email-select { ${addPixelToStyle(config.selectStyle || {})} }` : `${prefix} .cleverpush-email-select { ${addPixelToStyle(config.inputStyle || {})} }`}
    ${config.checkboxStyleActive ? `
      ${prefix} .cleverpush-email-checkbox label {
        padding-left: ${!config.checkboxHidden ? ((config.checkboxStyle?.width || 20) + 10) : 0}px;
        font-size: ${(config.checkboxStyle.fontSize || 17)}px;
        line-height: ${(config.checkboxStyle.height || 20)}px;
        box-sizing: border-box;
      }

      ${prefix} .cleverpush-email-topic-select-checkbox {
        width: ${(config.checkboxStyle?.width || 20)}px;
        height: ${(config.checkboxStyle?.height || 20)}px;
        box-sizing: border-box;
      }

      ${prefix} .cleverpush-email-checkbox label::before {
        content: '';
        display: inline-block;
        border: 1px solid ${config.checkboxStyle?.borderColor};
        left: 0;
        position: absolute;
        box-sizing: border-box;
        ${addPixelToStyle(config.checkboxStyle || {})}
      }

      ${prefix} .cleverpush-email-checkbox input[type="checkbox"]:checked + label::after {
        display: inline-block;
        transform: rotate(-10deg) scale(1) !important;
        content: '✔';
        color: ${config.checkboxTickStyle?.color};
        position: absolute;
        ${addPixelToStyle(config.checkboxTickStyle || {})};
        text-align: center;
        top: ${config.checkboxTickStyle?.top || 0}px;
        left: ${config.checkboxTickStyle?.left || 0}px;
        width: ${(config.checkboxStyle?.width || 20)}px;
        line-height: ${(config.checkboxStyle?.height || 20)}px;
        box-sizing: border-box;
      }

      ${prefix} .cleverpush-email-checkbox input[type="checkbox"] {
        width: auto !important;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        border: 1px solid ${config.checkboxStyle?.borderColor};
      }

      ${config.checkboxHidden ? `
        ${prefix} .cleverpush-email-checkbox label::before, ${prefix} .cleverpush-email-checkbox label::after {
          display: none !important;
        }
      ` : ''}
    ` : `
      ${prefix} .cleverpush-email-checkbox input[type="checkbox"], .cleverpush-email-field input[type="checkbox"] {
        width: auto !important;
        display: inline-block;
        position: initial;
        visibility: initial;
        appearance: auto;
        margin-right: 8px;
        margin-bottom: 0;
      }

    `}

    ${prefix} .cleverpush-email-field input[type="radio"] {
      width: auto;
      display: inline-block;
      position: initial;
      visibility: initial;
      appearance: auto;
      margin-right: 8px;
      margin-bottom: 0;
    }
    ${prefix} .iti { width: 100% }
    ${prefix} input[type="tel"] { padding-left: 52px }
    ${prefix} .cleverpush-email-field .cleverpush-email-checkbox { position: relative; line-height: 1; }
    ${prefix} .cleverpush-email-field .cleverpush-email-checkbox label { cursor: pointer; line-height: 1.25 !important; display: inline !important; }
    
    ${prefix} .cleverpush-email-field input::placeholder, .cleverpush-email-field textarea::placeholder { color: ${(config.inputStyle || {}).placeholderColor ? (config.inputStyle || {}).placeholderColor : ''} }
    ${prefix} .cleverpush-email-field input:-ms-input-placeholder, .cleverpush-email-field textarea:-ms-input-placeholder { color: ${(config.inputStyle || {}).placeholderColor ? (config.inputStyle || {}).placeholderColor : ''} }
    ${prefix} .cleverpush-email-field input::-webkit-input-placeholder, .cleverpush-email-field textarea::-webkit-input-placeholder { color: ${(config.inputStyle || {}).placeholderColor ? (config.inputStyle || {}).placeholderColor : ''} }
    ${prefix} .cleverpush-email-field .cleverpush-email-topics { ${addPixelToStyle(config?.topicStyle || {})} }
    ${prefix} .cleverpush-email-topic-name { ${addPixelToStyle(topicElementStyle?.topicNameStyle || {})} }
    ${prefix} .cleverpush-email-topic-subtitle { ${addPixelToStyle(topicElementStyle?.topicSubtitleStyle || {})} }
    ${prefix} .cleverpush-email-topic-description { ${addPixelToStyle(topicElementStyle?.topicDescriptionStyle || {})} }
    ${prefix} .cleverpush-email-topic-image img { ${addPixelToStyle(topicElementStyle?.topicImagetStyle || {})} }
    `;
    document.head.appendChild(style);

    if (config.customCss) {
      const node = document.createElement('style');
      node.innerHTML = config.customCss;
      document.body.appendChild(node);
    }

    formDiv.innerHTML = `
      <fieldset>
      <div class="cleverpush-email-form-overlay">
        <form action="" method="POST" class="cleverpush-email-form" id="cleverpush-email-form">
        ${config.widgetIconUrl ? `<div><img class="cleverpush-email-form-icon" style="max-width: 350px; display: none" src=${config.widgetIconUrl}></img></div>` : ''}
        <div class="cleverpush-email-form-content">
            ${config.headline ? `<div class="cleverpush-email-headline">${config.headline}</div>` : ''}
            ${config.subtitle ? `<div class="cleverpush-email-subtitle">${config.subtitle}</div>` : ''}
            ${config.description ? `<div class="cleverpush-email-description">${config.description}</div>` : ''}

            <input type="hidden" name="emailOptInFormId" value=${config._id} />

            ${config.fields.map((field) => {
    id += 1;
    const required = field.required || field.type === 'email';
    if (field.fieldVisibilty === FILED_OPTIONS_VISIBILITY_OPTION.showAfterSubcrtipion) {
      if (this.subscriptionId) {
        return this.renderOptinForm(field, id, required, config);
      }
      return '';
    }
    if (field.fieldVisibilty === FILED_OPTIONS_VISIBILITY_OPTION.showWithOutSubscription) {
      if (!this.subscriptionId) {
        return this.renderOptinForm(field, id, required, config);
      }
      return '';
    }

    return this.renderOptinForm(field, id, required, config);
  }).join('')}
          
          ${config.checkboxEnabled
    ? `<div class="cleverpush-email-field">
  <div class="cleverpush-email-checkbox">
  <input type="checkbox" id="${classNameId}-emailConfirm" name="emailConfirm" ${!config.checkboxOptional ? 'required' : ''} data-required-error="${config.checkboxErrorValue ? config.checkboxErrorValue : ''}">
          <label for="${classNameId}-emailConfirm">
          ${config.checkboxText || ''}
          </label>
          </div>
          </div>` : ''}
          ${!config.enableInlineButtonPosition ? `
            <button type="submit" class="cleverpush-email-button">
            ${config.buttonIcon ? `<image src="${config.buttonIcon}" class="cleverpush-email-button-icon"></image>` : ''}
            ${this.subscriptionId ? translate('panel.save') : (config.buttonContent || config.submitText || 'Absenden')}
            </button>` : ''}
          </div>
          </form>
          </div>
    </fieldset>
      <div class="cleverpush-opt-form-seprator">
        ${config?.enableSeparationDivider && !this.subscriptionId ? '<hr  />' : ''}
      </div>
      ${config?.enabledEditOptInForm && !this.subscriptionId ? `<fieldset>
      <form action="" method="POST" class="cleverpush-email-form" id="cleverpush-edit-optIn-form">
      ${config.editOptInFormFields?.map((editoptinField) => {
    editOptInId += 1;
    return (
      `<div class="cleverpush-email-field cleverpush-editOpt-field" id="cleverpush-editoptin-${editOptInId}">                  
    ${editoptinField.type === 'fieldHeading' && editoptinField.heading ? `<div class="cleverpush-email-headline">${editoptinField.heading}</div>` : ''}
    ${editoptinField.type === 'fieldDescription' && editoptinField.description ? `<div class="cleverpush-email-description">${editoptinField.description}</div>` : ''}
    ${editoptinField.type === 'email' ? `<label for="${`input-${editoptinField.type}`}">${editoptinField.label}</label>` : ''}
    ${editoptinField.type === 'email' ? `<input type="${editoptinField.type}" name="${inputType(editoptinField)}" id="${`input-${editoptinField.type}`}"  placeholder="${editoptinField.placeholder || ''}"  ${editoptinField.value ? `value="${editoptinField.value}"` : ''} />` : ''}
    ${editoptinField.type === 'button' ? `
      <button type="submit" class="cleverpush-email-button cleverpush-editOptIn-button" name="editOptinSendLink"}>
      ${config.buttonIcon ? `<image src="${config.buttonIcon}" class="cleverpush-email-button-icon"></image>` : ''}
      ${editoptinField.buttonLabel || 'einen Link schicken'}
      </button>` : ''}
</div>`
    );
  }).join('') || ''}
      </form>
  </fieldset>` : ''}
      
    `;

    const form = formDiv.querySelector('#cleverpush-email-form');
    const fieldset = formDiv.querySelector('fieldset');
    const requiredFields = formDiv.querySelectorAll('select:required, input:required');
    const requiredCheckboxes = formDiv.querySelectorAll('.cleverpush-email-topic-select-checkbox input[type=checkbox]');
    const doubleOptInCheckbox = formDiv.querySelector(`#${classNameId}-emailConfirm`);
    const editOptInForm = formDiv.querySelector('#cleverpush-edit-optIn-form');
    const telInputs = form.querySelectorAll('input[type="tel"]');
    for (const telInput of telInputs) {
      intlTelInput(telInput, { initialCountry: 'DE' });
    }

    if (doubleOptInCheckbox && config.checkboxEnabled && config.checkboxHidden) {
      doubleOptInCheckbox.checked = true;
      doubleOptInCheckbox.style.display = 'none';
    }

    const dateInputs = form.querySelectorAll('input[name="birthdate"]');
    for (const dateInput of dateInputs) {
      TinyDatePicker(dateInput, {
        lang: {
          days: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
          dayOffset: 0,
          months: [
            'Januar',
            'Februar',
            'März',
            'April',
            'Mai',
            'Juni',
            'Juli',
            'August',
            'September',
            'Oktober',
            'November',
            'Dezember',
          ],
          today: 'Heute',
          clear: 'Löschen',
          close: 'Schließen',
        },
        format(date) {
          let days = date.getDate();
          if (days.toString().length === 1) {
            days = `0${days}`;
          }
          let month = date.getMonth() + 1;
          if (month.toString().length === 1) {
            month = `0${month}`;
          }
          const year = date.getFullYear();
          return `${days}.${month}.${year}`;
        },
        parse(str) {
          const matches = dateRegex.exec(str);
          if (matches) {
            return new Date(`${matches[3]}-${matches[2]}-${matches[1]}T12:00:00`);
          }
          return new Date();
        },
      });
    }

    const validateFields = (typeOfEvent) => {
      function setAriaError(ariaError, field, errorMessage) {
        const oldAriaError = document.getElementById(`cleverpush-${field.id}`);
        if (!oldAriaError) {
          ariaError = document.createElement('div');
          ariaError.className = 'cleverpush-email-field-error';
          ariaError.setAttribute('role', 'error');
          ariaError.setAttribute('id', `cleverpush-${field?.id ? field.id : 'topic-checkbox'}`);
          ariaError.innerHTML = errorMessage;
          field.setCustomValidity(errorMessage);
          field.setAttribute('aria-invalid', 'true');
        }
        return ariaError;
      }

      const isAtLeastOneCheckboxChecked = formDiv.querySelectorAll('.cleverpush-email-topic-select-checkbox input[type=checkbox]:checked').length > 0;
      const checkboxAriaError = document.getElementById('cleverpush-topic-checkbox');
      let ariaError;

      if (requiredCheckboxes.length) {
        if (isAtLeastOneCheckboxChecked) {
          requiredCheckboxes.forEach((checkbox) => checkbox.removeAttribute('required'));
          requiredCheckboxes[0].setCustomValidity('');
          requiredCheckboxes[0].setAttribute('aria-invalid', 'false');

          if (checkboxAriaError) {
            formDiv.querySelector('.cleverpush-email-topics').removeChild(checkboxAriaError);
          }
        } else if (typeOfEvent === 'submit') {
          requiredCheckboxes[0].setAttribute('required', '');

          if (requiredCheckboxes[0].dataset.requiredError) {
            ariaError = setAriaError(ariaError, requiredCheckboxes[0], requiredCheckboxes[0].dataset.requiredError);
            formDiv.querySelector('.cleverpush-email-topics').appendChild(ariaError, requiredCheckboxes[0]);
          }
        }
      }

      for (const field of requiredFields) {
        const oldAriaError = document.getElementById(`cleverpush-${field.id}`);

        if (!field.value) {
          if (oldAriaError) {
            return;
          }

          if (field.dataset.requiredError) {
            ariaError = setAriaError(ariaError, field, field.dataset.requiredError);
          }

          if (typeOfEvent === 'submit' && !oldAriaError && ariaError) {
            field.parentNode.appendChild(ariaError, field);
          }
        } else if (field.validity.typeMismatch) {
          if (field.dataset.requiredErrorMismatch) {
            ariaError = setAriaError(ariaError, field, field.dataset.requiredErrorMismatch);
          }

          if (typeOfEvent === 'submit' && !oldAriaError && ariaError) {
            field.parentNode.appendChild(ariaError, field);
          }
        } else {
          field.setCustomValidity('');
          field.setAttribute('aria-invalid', 'false');

          if (oldAriaError && typeOfEvent === 'input') {
            field.parentNode.removeChild(oldAriaError);
          }
        }
      }
    };

    const formButton = form.querySelector('button');
    formButton.addEventListener('click', () => {
      validateFields('submit');
      if (doubleOptInCheckbox && !doubleOptInCheckbox.checked) {
        doubleOptInCheckbox.setCustomValidity(doubleOptInCheckbox.dataset.requiredError);
        doubleOptInCheckbox.setAttribute('aria-invalid', 'true');
      }
    });

    form.addEventListener('input', (e) => {
      e.preventDefault();
      validateFields('input');
    });

    form.addEventListener('submit', (e) => {
      e.preventDefault();

      if (config?.redirectOnSubscribe && config?.suppressSubscribe && config?.redirectUrl) {
        openRedirectUrl(config.redirectUrl, config?.redirectInNewTab);
        return;
      }

      const selectedCheckboxLength = form.querySelectorAll('input[type="checkbox"]:checked');
      const topicField = (config.fields || []).find((field) => field.type === 'topics');

      if (topicField && topicField.required && selectedCheckboxLength.length === 0 && !this.subscriptionId) {
        alert(translate('alert.checkboxRequired'));
        return;
      }

      let optInText; // Choose the last checkbox field for now
      const lastCheckboxField = form.querySelector('.cleverpush-email-field input[type="checkbox"]');
      if (lastCheckboxField && lastCheckboxField.parentNode) {
        optInText = lastCheckboxField.parentNode.innerText;
      }

      const emailField = form.querySelector('.cleverpush-email-field input[type="email"]');
      if (emailField && !emailField.value) {
        return;
      }
      const phoneField = form.querySelector('.cleverpush-email-field input[type="tel"]');
      if (phoneField && !phoneField.value) {
        return;
      }

      const data = {
        ...this.formSerialize(form),
        formId: config._id,
        tags: config.tags,
        topics: this.overrideTopics || config.topics,
        optInText,
        optInUrl: location.href
      };

      const sharedChannels = sharedWidgetConfig?.sharedChannels;
      const isSharedChannels = sharedChannels?.includes(this.channelId);

      if (isSharedChannels) {
        data.isSharedChannels = true;
        data.widgetId = sharedWidgetConfig._id;
        data.channelId = sharedWidgetConfig.channel;
      }

      data.connectedSubscriptionId = typeof localStorage !== 'undefined' ? localStorage.getItem('cleverpush-subscription-id') || '' : '';

      if (config.topics?.length > 0) {
        data.addTopics = true;
      }

      if (config.tags?.length > 0) {
        data.addTags = true;
      }

      const topicsDiv = form.querySelector('.cleverpush-email-topics');
      if (topicsDiv) {
        delete data['topics[]'];
        if (!data.topics) {
          data.topics = [];
        }

        const topicCheckboxes = topicsDiv.querySelectorAll('input[name="topics[]"]');
        if (topicCheckboxes && topicCheckboxes.length) {
          topicCheckboxes.forEach((input) => {
            if (input.type === 'hidden' || input.checked) {
              data.topics.push(input.value);
            }
          });
        }
      }

      if (data.birthdate) {
        const matches = dateRegex.exec(data.birthdate);
        if (!matches) {
          alert('Bitte wähle ein Datum aus.');
          return;
        }
        const date = new Date(`${matches[3]}-${matches[2]}-${matches[1]}T12:00:00`);
        data.birthdate = date;
      }

      if (this.isEditTopics) {
        data.isEditTopics = true;
      }

      fieldset.disabled = true;
      // eslint-disable-next-line consistent-return
      const options = {
        timeout: 1000 * 30,
      };
      return this.api.request('POST', `/subscription/sync/${this.channelId}`, data, options).then((json) => new Promise((resolve, reject) => {
        if (json && json.id) {
          resolve(json);
        } else if (json && json.error) {
          reject(json.error);
        } else {
          reject(json);
        }
      }).then((res) => {
        if (res.redirectUrl) {
          if (config?.redirectOnSubscribe && config?.redirectUrl) {
            openRedirectUrl(config.redirectUrl, config?.redirectInNewTab);
          } else {
            location.href = res.redirectUrl;
          }
        } else {
          const defaultContent = `
          <div class="cleverpush-email-overlay-answer">
            ${(res?.emailAddressExists && config.alreadySubscribedInfoEnabled && config.alreadySubscribedInfoText)
              ? config.alreadySubscribedInfoText
              : config.emailOptInRoutineSuccessMessage
                ? config.emailOptInRoutineSuccessMessage
                : '<h1><b>Vielen Dank</b></h1><p>Sie erhalten nun eine E-Mail mit einem Bestätigungslink von uns. Bitte öffnen Sie diesen, um die Anmeldung abzuschließen.</p>'}
            </div>
            `;

          formDiv.querySelector('.cleverpush-email-form-content').style.opacity = '0.15';

          form.innerHTML += (config.successContent || defaultContent);

          if (this.subscriptionId) {
            const url = window.location.href.split('?')[0];
            window.history.replaceState({}, '', url);
          }
          const optInForm = formDiv.querySelector('#cleverpush-edit-optIn-form');
          const formSeprator = formDiv.querySelector('.cleverpush-opt-form-seprator');
          if (optInForm) {
            optInForm.style.display = 'none';
            formSeprator.style.display = 'none';
          }
          if (config?.redirectOnSubscribe && config?.redirectUrl) {
            location.href = config.redirectUrl;
          }
        }
      })).catch((err) => {
        console.error(err);
        if (err.informationMessage && err.scrollToSecondOptInForm) {
          document.getElementById('cleverpush-edit-optIn-form').scrollIntoView(AUTOMATIC_SCROLL_BEHAVIOR);
          return new ToastAlert(err.informationMessage, 'error', DEFAULT_TIME_TO_SHOW_TOAST);
        }
        fieldset.disabled = false;

        alert(translate('alert.unexpected'));
      });
    });

    if (editOptInForm) {
      editOptInForm.addEventListener('submit', (e) => {
        e.preventDefault();

        const email = editOptInForm.querySelector('.cleverpush-editOpt-field input[type="email"]');
        if (email && !email.value) {
          return;
        }

        if (email.value) {
          try {
            const data = {
              websiteURL: window.location.href,
              email: email.value,
              optInFormId: config._id,
              channelId: this.channelId,
            };
            const http = new XMLHttpRequest();
            const sendButton = editOptInForm.querySelector('.cleverpush-editOptIn-button');
            if (sendButton) {
              sendButton.disabled = true;
              sendButton.innerText = 'Senden...';
            }
            http.open('POST', `${this.api.endpoint}/email/send-edit-email`, true);
            http.onreadystatechange = function () {
              if (http.readyState === 4) {
                if (http.status === 200) {
                  setTimeout(() => {
                    sendButton.innerText = 'Senden';
                    sendButton.disabled = false;
                    new ToastAlert(translate('alert.successEmailSend'), 'success', DEFAULT_TIME_TO_SHOW_TOAST);
                    editOptInForm.reset();
                  }, 2000);
                } else {
                  const response = JSON.parse(http.responseText);
                  sendButton.innerText = 'Senden';
                  sendButton.disabled = false;
                  editOptInForm.reset();
                  new ToastAlert(response.message || translate('alert.unexpected'), 'error', DEFAULT_TIME_TO_SHOW_TOAST);
                }
              }
            };
            http.send(JSON.stringify(data));
          } catch (error) {
            console.log(error);
          }
        }
      });
    }

    const url = new URL(window.location.href);
    const mySearchParams = new URLSearchParams(url.search);

    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of mySearchParams) {
      if (key === 'topics') {
        const parseTopic = value.split(',');
        const topicsDiv = formDiv.querySelector('.cleverpush-email-topics');
        if (topicsDiv) {
          const topicCheckboxes = topicsDiv.querySelectorAll('input[type="checkbox"]');
          if (topicCheckboxes && topicCheckboxes.length) {
            for (let checkboxIndex = 0; checkboxIndex < topicCheckboxes.length; checkboxIndex += 1) {
              const checkbox = topicCheckboxes[checkboxIndex];
              if (parseTopic.includes(checkbox.value)) {
                checkbox.checked = true;
              }
            }
          }
        }
      } else if (key === 'email') {
        const email = formDiv.querySelectorAll('.cleverpush-email-field input[type="email"]');
        if (email && email.length) {
          email.forEach((input) => {
            input.value = value;
          });
        }
      } else if (key === 'birthdate') {
        const birthdate = formDiv.querySelectorAll('.cleverpush-email-field input[type="date"]');
        if (birthdate && birthdate.length) {
          birthdate.forEach((input) => {
            input.value = value;
          });
        }
      } else if (key === 'isEditTopics') {
        this.isEditTopics = value;
      } else if (autoField[key]) {
        const input = formDiv.querySelector(`.cleverpush-email-field input[name="${key}"]`);
        if (input) {
          input.value = value;
        }
      } else {
        const customAttributes = document.getElementsByName(`customAttributes[${key}]`);
        customAttributes?.forEach((customAttribute) => {
          const customAttributeElement = document.getElementById(customAttribute.id);
          if ((customAttributeElement.type === 'radio' || customAttributeElement.type === 'checkbox') && customAttributeElement?.id?.includes(key) && customAttributeElement.value === value) {
            customAttributeElement.checked = true;
          }
          customAttributeElement.value = value;
        });
      }
    }

    if (this.config.customAttributeValues) {
      Object.keys(this.config.customAttributeValues).forEach((customAttributeId) => {
        const value = this.config.customAttributeValues[customAttributeId];
        const customAttributeElement = document.getElementById(customAttributeId);
        if ((customAttributeElement.type === 'radio' || customAttributeElement.type === 'checkbox') && customAttributeElement?.id?.includes(customAttributeId) && customAttributeElement.value === value) {
          customAttributeElement.checked = true;
        }
        customAttributeElement.value = value;
      });
    }

    const emailElement = document.querySelector('#cleverpush-email-form');
    const emailIconElement = document.querySelector('.cleverpush-email-form-icon');
    const widgetIconElement = document.querySelector('.cleverpush-widget-icon');

    if (emailElement?.clientHeight && emailIconElement && config.iconFullHeight) {
      emailIconElement.style.height = `${emailElement?.clientHeight}px`;
      emailIconElement.style.display = 'block';
      if (widgetIconElement) {
        widgetIconElement.style.display = 'none';
      }
    } else if (emailIconElement) {
      emailIconElement.style.display = 'block';
      if (widgetIconElement) {
        widgetIconElement.style.display = 'none';
      }
    }
  }

  formSerialize(formElement) {
    const values = {};
    const inputs = formElement.elements;
    for (let i = 0; i < inputs.length; i += 1) {
      if (inputs[i].name && inputs[i].name !== 'emailValidation') {
        if ((inputs[i].name === 'emailConfirm' || inputs[i].type === 'radio' || inputs[i].type === 'checkbox') && !inputs[i].checked) {
          continue;
        }
        const objectRegex = new RegExp('([a-z0-9_]+)\\[([a-z0-9_]+)\\]', 'gmi');
        const matches = objectRegex.exec(inputs[i].name);
        if (matches && matches.length > 1 && matches[1] && matches[2]) {
          if (typeof values[matches[1]] !== 'object') {
            values[matches[1]] = {};
          }
          values[matches[1]][matches[2]] = inputs[i].value;
        } else {
          values[inputs[i].name] = inputs[i].value;
        }
      }
    }
    return values;
  }
}

export default CleverPushEmail;
